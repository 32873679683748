@import '~bootstrap/scss/bootstrap';
@import './styles/variables';

@font-face {
  font-family: 'circular-book';
  src: url('./styles/fonts/circular_book.ttf');
}

@font-face {
  font-family: 'circular-book-italic';
  src: url('./styles/fonts/circular_book_italic.ttf');
}

@font-face {
  font-family: 'circular-black';
  src: url('./styles/fonts/circular_black.ttf');
}

@font-face {
  font-family: 'circular-medium';
  src: url('./styles/fonts/circular_medium.ttf');
}

@font-face {
  font-family: 'circular-bold';
  src: url('./styles/fonts/circular_bold.ttf');
}

.container {
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: 30px;
  @media screen and (min-width: 460px) {
    margin-top: 80px;
    width: 460px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
hr {
  padding: 0;
}

.StripeElement {
  box-sizing: border-box;
  padding: 15px 12px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $input-border;
}

.StripeElement--focus {
  background-color: #fff;
  border-color: $input-border;
  box-shadow: none;
}

.StripeElement--invalid {
  border-color: #ff5454;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.border-top-none {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.text-danger {
  font-family: 'circular-book';
}

.line {
  width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
  @media screen and (min-width: 460px) {
    margin-left: -15px;
    width: 459px;
  }
}

@import './variables';

.header {
  height: 94px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $border-color;
  img {
    width: 80px;
  }
}

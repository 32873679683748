.success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 62px;
  padding-bottom: 44px;
  padding-left: 44px;
  padding-right: 44px;

  h1 {
    font-size: 32px;
    font-family: 'circular-bold';
    text-align: center;
  }
  .details {
    font-family: 'circular-book';
    font-size: 14px;
  }
  .line {
    width: 160px;
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
  }
  .info {
    color: #3f9d76;
    font-size: 14px;
    font-family: 'circular-book-italic';
    text-align: center;
  }
}

@import './variables';
.input-label {
  font-family: 'circular-bold';
  font-size: 18px;
}

.input-group {
  input {
    border: 1px solid $input-border;
    padding: 14px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 15px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none;
  }
  input:read-only {
    background-color: white;
  }
  .input-group-prepend {
    background-color: white;
  }
  .input-group-text {
    background-color: white;
    border: 1px solid $input-border;
    font-size: 15px;
  }
}
.no-select:focus {
  border: none;
  box-shadow: none;
  border: 1px solid $input-border;
}

@import './variables';

.service-description {
  background-color: $secondary;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 22px;
  padding-bottom: 40px;
  margin-bottom: 22px;

  h1 {
    text-align: center;
    margin-top: 40px;
    font-family: 'circular-bold';
    span {
      color: $green;
      font-family: 'circular-bold';
    }
  }
  .break-line {
    margin-top: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid black;
    width: 160px;
  }
  p {
    font-family: 'circular-book';
    font-size: 16px;
  }
  ul {
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
    font-family: 'circular-book';
    font-size: 16px;
  }
  .underline-text {
    font-family: 'circular-book-italic';
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #5093cc;
  }
}

.refund-info {
  background-color: #f0f9f6;
  font-size: 14px;
  font-family: 'circular-book';
  padding: 22px;
  margin-bottom: 80px;
}

.submit-button {
  margin-top: 40px;
  width: '100%';
  height: 48px;
  cursor: pointer;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'circular-medium';
  background-color: black;
}
.submit-button:hover {
  color: white;
}

.privacy-policy {
  font-size: 12px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    font-family: 'circular-book';
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #5093cc;
  }
}

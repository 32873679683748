.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #cdcdcd;
  border-radius: 16px;
  font-family: 'circular-medium';
  line-height: 1.125em;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 46px;
  padding-top: 24px;
  @media screen and (max-width: 460px) {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  text-decoration: none;
  font-family: 'circular-book';
  abbr {
    text-decoration: none;
  }
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: black;
}
.calendar-disabled-neighboringMonth {
  color: #cdcdcd;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  width: 40px;
  font-size: 16px;
  border-radius: 20px;
  height: 40px;
  font-family: 'circular-book';
  background: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #5093cc;
}
.react-calendar__tile--active {
  background: #5093cc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #5093cc;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

// Service slots
.service-slot {
  margin: 10px;
  margin-top: 20px;

  cursor: pointer;
  height: 45px;
  border-radius: 28px;
  border: 1px solid #eceef2;
  color: black;
  font-family: 'circular-medium';
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-slot-active {
  border-radius: 28px;
  background-color: #5093cc;
  color: white;
}

.slot-tip {
  font-family: 'circular-book-italic';
  font-size: 14px;
  color: #4f4f4f;
}

.calendar-label {
  margin-top: 12px;
  margin-bottom: 24px;
}
